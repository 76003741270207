import React from "react"
import parse from "html-react-parser"
import { bytesToSize, youtubeId } from "~/utils/helpers"
import useSupplierNewsItem from "~/hooks/useSuppliersNewsItem"
import EditSupplierPost from "~/components/routes/EditSupplierPost"
import PreviewImage from "~/components/reusable/PreviewImage"
import PostImage from "~/components/reusable/PostImage"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import fileIcon from "../../images/file-icon.png"
import Page from "../reusable/Page"
import LinkedAccountManager from "../LinkedAccountManager"
import useUser from "~/hooks/useUser"
import useImages from "~/hooks/useImages"
import useFiles from "~/hooks/useFiles"
import Link from "../Link"
import VideoEmbed from "../reusable/VideoEmbed"
import GoBack from "../reusable/GoBack"
import { ImageSkeleton, TextSkeleton } from "../reusable/Skeleton"
import { ButtonLink } from "../reusable/Button"
import PublishRequest from "../PublishRequest"
import { FaEdit } from "react-icons/fa"

type Props = {
  slug: string
  path: string
}
export default function SingleSupplierPost({ slug, path }: Props) {
  const { user } = useUser()
  const { data, loading, error } = useSupplierNewsItem(slug)

  // check if path contains /edit
  const isEdit = path.includes("/edit")
  if (isEdit) {
    return <EditSupplierPost postData={data} />
  }

  if (loading) return <SkeletonPost />

  const {
    title,
    featuredImage,
    newsPost: {
      postContent,
      documents,
      videoUrl,
      publishedStatus,
      approvalStatus,
      linkedSupplier,
      expiryDate,
    },
  } = data

  const banner = useImages(featuredImage?.node?.fileName)
  const fallbackImage = featuredImage?.node?.mediaItemUrl
  const supplierLogo =
    linkedSupplier[0]?.supplierDetails?.profilePicture?.mediaItemUrl

  const hasExpired =
    new Date(expiryDate) < new Date() && user?.userGroup !== "suppliers"

  if (hasExpired) {
    return (
      <Page title={title}>
        <div className={`bbl-post-wrap-block bg-theme-yellow py-8 lg:py-16`}>
          <Wrapper>
            <p className="text-h5 lg:text-h3">This post has expired!</p>
          </Wrapper>
        </div>
      </Page>
    )
  }

  return (
    <Page title={title}>
      {user?.userGroup === "suppliers" ? (
        <div className="fixed top-0 left-0 right-0 z-[2] min-h-[100px] w-full rounded-br-3xl rounded-bl-3xl bg-theme-orange p-4 py-8">
          <Wrapper
            className={`items-center justify-center px-3 max-md:space-y-6 md:justify-between`}
          >
            <h2 className="hidden text-h3 font-bold md:block md:text-h2">
              Article Editor
            </h2>
            <div className="!mt-0 flex gap-2">
              <ButtonLink
                link={`/portal/suppliers/posts/edit/${slug}`}
                text={
                  <span className="flex gap-2">
                    <FaEdit /> Edit
                  </span>
                }
                buttonStyle="primary"
                buttonColor="light-green"
                className="!mb-0"
              />
              <PublishRequest updating={false} post={data} buttonStyle={true} />
            </div>
          </Wrapper>
        </div>
      ) : null}
      <div
        className={`bd-post-banner-block relative bg-theme-yellow pt-6 pb-20 md:pt-14 ${
          hasExpired ? "blur-sm" : ""
        }`}
      >
        <div
          className={`bar absolute top-0 left-0 right-0 bg-theme-green-light `}
        >
          <div className={`block h-[185px] bg-theme-green-light`} />
          <Spacer top={`green-light`} bottom={`yellow`} />
        </div>
        <Wrapper className={`relative z-[1]`}>
          <div
            className={`banner flex-set-0-0 relative mx-auto basis-almost-full lg:basis-almost-5/6`}
          >
            {fallbackImage && (
              <PostImage
                alt={title || banner.id}
                fallbackUrl={fallbackImage}
                className={`md:vs-shadow aspect-video w-full rounded-t-[32px] md:rounded-[32px]`}
              />
            )}
            <div
              className={`con-wrap relative rounded-b-[32px] bg-theme-white p-8 pb-24 md:absolute md:bottom-0 md:left-0 md:right-28 md:rounded-tr-[32px] md:px-10 md:py-14 [&_.bd-core-heading-block]:mx-0 [&_.bd-core-heading-block]:max-w-4/5 [&_.bd-core-heading-block]:leading-normal`}
            >
              <div
                className={`line absolute bottom-full left-0 block h-[6px] w-4/5 bg-theme-orange `}
              />
              <h1
                className={`bd-core-heading-block mx-auto max-w-screen-2xl px-3 text-h4 font-bold text-theme-black md:text-h3`}
              >
                {title}
              </h1>
              <div
                className={`logo absolute left-[calc(50%_-_77px)] bottom-[-77px] flex h-[154px] w-[154px] items-center justify-center overflow-hidden rounded-full border-8 border-theme-orange bg-theme-white p-4 md:left-auto md:right-[-77px] md:bottom-[calc(50%_-_77px)]`}
              >
                <PostImage
                  alt={linkedSupplier?.title || ""}
                  fallbackUrl={supplierLogo}
                  className={``}
                />
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
      {hasExpired ? (
        <div className="text-lg lg:text-h4 fixed top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2 transform rounded-2xl bg-theme-orange-light/80 p-4 font-semibold text-theme-orange backdrop-blur-sm lg:p-6">
          This post has expired
        </div>
      ) : null}
      <div
        className={`bbl-post-wrap-block bg-theme-yellow  ${
          hasExpired ? "select-none blur-sm" : ""
        }`}
      >
        <Wrapper className={`relative`}>
          <div className={"actions mb-4 px-3 md:mb-[-38px]"}>
            <GoBack />
          </div>
          <div
            className={`wrap flex-set-0-0 mx-auto basis-full md:mx-1/12 md:basis-5/6 lg:mx-1/6 lg:basis-2/3 [&_p]:mb-8 [&_p]:leading-8 [&_.bd-core-heading-block]:mb-8 [&_.gatsby-caption-wrapper]:mx-auto [&_.gatsby-caption-wrapper]:mb-8 [&_.gatsby-caption-wrapper]:max-w-almost-full [&_.wrapper]:w-full [&_.bd-video-block]:my-14 [&_.bd-button-group-block]:mb-8 [&_ul]:mb-8 [&_ol]:mb-8`}
          >
            {postContent && parse(postContent)}
            {videoUrl && <VideoEmbed url={videoUrl || ""} />}
            {documents && (
              <div
                className={`mb-10 rounded-3xl bg-theme-white p-10 md:mb-[72px]`}
              >
                <h5
                  className={`mb-5 text-h6 font-black tracking-[-0.024em] md:text-h5`}
                >
                  Documents
                </h5>
                {documents.map(
                  (doc: any, index: React.Key | null | undefined) => (
                    <Document key={index} file={doc} />
                  )
                )}
              </div>
            )}
          </div>
        </Wrapper>
      </div>
      {user?.userGroup !== "suppliers" ? (
        <>
          <Spacer top="yellow" bottom="orange-lighter" />
          <LinkedAccountManager
            postcode={user?.linkedGroupData?.memberDetails?.postcode || ""}
          />
          <div className={`relative z-[2]`}>
            <Spacer top="transparent" bottom="black" />
          </div>
        </>
      ) : (
        <div className={`relative z-[2]`}>
          <Spacer top="yellow" bottom="black" />
        </div>
      )}
    </Page>
  )
}

export const Document = ({ file }: { file: any }) => {
  const docFile = file?.file ? file?.file : file
  const localFile = useFiles(docFile?.fileName)

  if (!docFile) return null

  return (
    <Link
      to={localFile?.publicURL || file?.file?.mediaItemUrl}
      className={`mb-4 flex items-center rounded-xl p-3 transition hover:bg-theme-orange-lighter/50 md:mx-auto md:w-3/4 [&_p]:mb-0 [&_p]:leading-normal [&_p]:tracking-normal`}
      key={file?.file?.mediaItemUrl}
      activeClassName={undefined}
      forceBlank={undefined}
    >
      <div className={`icon relative w-10 flex-none`}>
        <img src={fileIcon} className={`w-10`} />
        <span
          className={`absolute bottom-2 left-1 right-1 text-center text-[12px] font-bold uppercase tracking-wider text-theme-orange`}
        >
          {docFile?.mediaItemUrl
            ? docFile?.mediaItemUrl.substr(
                docFile?.mediaItemUrl.lastIndexOf(".") + 1
              )
            : ``}
        </span>
      </div>
      <div className={`flex-1 px-8`}>
        <p className={`text-small font-bold`}>
          {file?.title ? file?.title : docFile?.fileName}
        </p>
        <p className={`text-caption text-theme-gray-mid`}>
          {bytesToSize(docFile?.size ? docFile?.size : docFile?.fileSize)}
        </p>
      </div>
    </Link>
  )
}

const SkeletonPost = () => {
  return (
    <Page title="Loading...">
      <div
        className={`bd-post-banner-block relative bg-theme-yellow pt-6 pb-20 md:pt-14`}
      >
        <div
          className={`bar absolute top-0 left-0 right-0 bg-theme-green-light`}
        >
          <div className={`block h-[185px] bg-theme-green-light`} />
          <Spacer top={`green-light`} bottom={`yellow`} />
        </div>
        <Wrapper className={`relative z-[1]`}>
          <div
            className={`banner flex-set-0-0 relative mx-auto basis-almost-full lg:basis-almost-5/6`}
          >
            <ImageSkeleton />
            <div
              className={`con-wrap relative rounded-b-[32px] bg-theme-white p-8 pb-24 md:absolute md:bottom-0 md:left-0 md:right-28 md:rounded-tr-[32px] md:px-10 md:py-14 [&_.bd-core-heading-block]:mx-0 [&_.bd-core-heading-block]:max-w-4/5 [&_.bd-core-heading-block]:leading-normal`}
            >
              <div
                className={`line absolute bottom-full left-0 block h-[6px] w-4/5 bg-theme-orange `}
              />
              <h1
                className={`bd-core-heading-block mx-auto max-w-screen-2xl px-3 text-h4 font-bold text-theme-black md:text-h3`}
              >
                <TextSkeleton />
              </h1>
              <div
                className={`logo absolute left-[calc(50%_-_77px)] bottom-[-77px] flex h-[154px] w-[154px] items-center justify-center overflow-hidden rounded-full border-8 border-theme-orange bg-theme-white p-4 md:left-auto md:right-[-77px] md:bottom-[calc(50%_-_77px)]`}
              >
                <ImageSkeleton />
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
      <div className={`bbl-post-wrap-block bg-theme-yellow pb-16`}>
        <Wrapper className={`relative`}>
          <div
            className={`wrap flex-set-0-0 mx-auto basis-full md:mx-1/12 md:basis-5/6 lg:mx-1/6 lg:basis-2/3`}
          >
            <TextSkeleton paragraphs={3} />
          </div>
        </Wrapper>
      </div>
      <div className={`relative z-[2]`}>
        <Spacer top="transparent" bottom="black" />
      </div>
    </Page>
  )
}
