import React, { useEffect, useState } from "react"
import FormFields from "~/components/FormFields"
import { formFieldSets } from "~/data/newsPostFields"
import { useAddSupplierNewsItemMutation } from "~/graphql/generated/graphql"
import useUser from "~/hooks/useUser"
import { navigate } from "gatsby"
import { slugify } from "~/utils/helpers"
import { FaCheck } from "react-icons/fa"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import Page from "../reusable/Page"
import Button from "../reusable/Button"
import { GET_SUPPLIER_NEWS, GET_SUPPLIER_NEWS_ITEM } from "~/lib/queries"

export default function AddSupplierPost() {
  const [newsPostFields, setNewsPostFields] = useState(formFieldSets)

  const [AddSupplierNewsItemMutation, { data, loading, error }] =
    useAddSupplierNewsItemMutation()

  const { user } = useUser()
  const supplier = user?.linkedGroupData

  // add ref to all fields
  useEffect(() => {
    const fields = newsPostFields.map(fieldSet => {
      return {
        ...fieldSet,
        fields: fieldSet.fields.map(field => {
          return {
            ...field,
            ref: React.createRef(),
          }
        }),
      }
    })

    clearFields()
  }, [])

  // use ref to set the value of the field to ""
  const clearFields = () => {
    newsPostFields.forEach(fieldSet => {
      fieldSet.fields.forEach(field => {
        console.log(field?.ref)
        // if (field?.ref?.current) {
        //   field?.ref?.current?.value = ""
        // }
      })
    })
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const form = event.currentTarget || event.target
    const formData: FormData | any = new FormData(form)
    const values = Object.fromEntries(formData)

    if (!values) {
      return
    }

    // set the document title, there is only a single item when creating a post
    const docTitle = values[`documentTitle-${values["documents"]}`]

    // //console.log(values);
    // console.log({
    //   title: values.postTitle,
    //   slug: slugify(values.postTitle),
    //   expiryDate: values.expiryDate,
    //   publishedStatus: Boolean(values.publishedStatus === "true"),
    //   image: values.postImage,
    //   postContent: values.postContent,
    //   category: values.category,
    //   videoUrl: values.videoUrl,
    //   documents: values.documents,
    //   documentsTitles: JSON.stringify({
    //     0: { id: parseInt(values.documents), title: docTitle },
    //   }),
    //   linkedSupplier: supplier?.databaseId as any,
    // })

    AddSupplierNewsItemMutation({
      variables: {
        title: values.postTitle,
        slug: slugify(values.postTitle),
        expiryDate: values.expiryDate,
        publishedStatus: Boolean(values.publishedStatus === "true"),
        image: values.postImage,
        postContent: values.postContent,
        category: values.category,
        videoUrl: values.videoUrl,
        documents: values.documents,
        documentsTitles: JSON.stringify({
          0: { id: parseInt(values.documents), title: docTitle },
        }),
        linkedSupplier: supplier?.databaseId as any,
      },
      refetchQueries: [
        { query: GET_SUPPLIER_NEWS },
        {
          query: GET_SUPPLIER_NEWS_ITEM,
          variables: { slug: slugify(values.postTitle) },
        },
      ],
    })
  }

  // unmount and remount Form component
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  // undo pre-populate form fields
  useEffect(() => {
    let fields: any[] = []

    // update supplier fields
    newsPostFields.forEach((fieldSet: any) => {
      fieldSet.fields.forEach((field: any) => {
        if (field.dataField) {
          if (field.dataField.includes(".")) {
            const nestedFields = field.dataField.split(".")
            if (!nestedFields.includes("publishedStatus" || "approvalStatus")) {
              field.value = ""
            } else {
              field.value = false
            }
          } else {
            field.value = ""
          }
          field.loaded = true
        }
      })
      fields.push(fieldSet)
    })
    setNewsPostFields(fields)
  }, [])

  if (data) {
    // redirect to edit page
    navigate(
      `/portal/suppliers/posts/edit/${data?.createSupplierNews?.supplier_news?.slug}`
    )
  }

  return (
    <Page title={`Add new post | Vetshare`}>
      <div className={`bg-theme-yellow-light pt-6`}>
        <Wrapper>
          <form
            className={`flex-set-0-0 basis-full`}
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="items-start md:flex">
              <div
                className={`vs-shadow-light flex-set-0-0 top-8 mx-3 mb-8 h-fit basis-almost-1/4 overflow-hidden rounded-3xl bg-theme-white pt-8 md:sticky [&_.brew-button]:mx-auto [&_.brew-button]:mb-6 [&_.brew-button]:block`}
              >
                <h1 className="mb-7 px-11 text-h4 font-bold md:text-h3">
                  Post Editor
                </h1>
                <Button
                  type="submit"
                  disabled={loading}
                  text={
                    loading ? (
                      "Saving..."
                    ) : (
                      <>
                        <i
                          className={`mr-2 inline-block translate-y-[-2px] align-middle`}
                        >
                          <FaCheck />
                        </i>
                        Save New Article
                      </>
                    )
                  }
                  buttonColor="light-green"
                />
              </div>

              {mounted && (
                <div className="relative mx-auto basis-almost-7/12 @container/form-parent [&_.text-field]:@md:w-7/12 [&_.email-field]:@md:w-7/12 [&_.categories-field]:@md:w-7/12 [&_.linked-field]:@md:w-7/12 [&_.select-field]:@md:w-7/12 [&_.date-field]:@md:w-7/12 [&_.radio-field]:@md:w-7/12">
                  {formFieldSets.map(({ name, label, fields }) => (
                    <fieldset
                      key={name}
                      disabled={loading}
                      aria-busy={loading}
                      className={`pt-4`}
                    >
                      <h2 className="mb-10 text-h3 md:text-h2">
                        News Post Editor
                      </h2>
                      <FormFields fields={fields} />
                    </fieldset>
                  ))}
                </div>
              )}
            </div>
          </form>
        </Wrapper>
      </div>
      <Spacer top={`yellow-light`} bottom={`black`} />
    </Page>
  )
}
